@import '@/scss/import.scss';

.button[data-type='primary'] {
  @extend %btn_primary;
}

.button[data-type='secondary'] {
  @extend %btn_secondary;
}

.button[disabled='disabled'],
.button[data-type='disabled'],
.button:disabled {
  @extend %btn_disabled;
  cursor: not-allowed;
}

.loading {
  width: max(20px, 3rem);
  height: max(20px, 3rem);
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #ffffff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6.4px), #000 0);
  animation: spinner 0.8s infinite linear;
}

.loading_sec {
  @extend .loading;
  background: conic-gradient(#0000 10%, $col-primary);
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
  }
}
