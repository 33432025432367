@import '@/scss/import.scss';

.pro {
  @extend %center;
  flex-direction: column;
  text-align: center;

  padding-block: max(12px, 1.6rem);
  padding-inline: max(10px, 2rem);
  gap: max(8px, 0.8rem);

  width: 100%;
  background: $col-neutral-400;
  border-radius: $border-radius;

  h4 {
    @extend %text-12;
    color: $col-black;
  }

  p {
    @extend %text-12;
    line-height: max(16px, 1.8rem);
  }

  .pro_button {
    @extend %text-12;
    width: max(120px, 12.3rem);
    height: max(30px, 3.6rem);
    border-radius: max(0.8rem, 8px);
  }
}
