@import '@/scss/import.scss';

.transaction {
  border-bottom: 1px solid $col-neutral-500;

  &:hover {
    background: rgba(248, 248, 248, 0.5);
  }
  td {
    padding-block: $border-radius;
    text-align: left;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      p {
        width: 96%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }

    &:last-child {
      text-align: right;
    }
  }

  h4 {
    @extend %text-14;
  }

  p {
    @extend %text-14;
  }

  span {
    @extend %center;
    display: inline-flex;
    padding: max(0.3rem, 3px) max(8px, 1.2rem);
    border-radius: $border-radius;
    @extend %text-14;
  }
}

.transaction_modal {
  @extend %center;
  @extend %cover;
  z-index: 1;
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  &_main {
    padding-block: $padding-inline;
    padding-inline: max(30px, 5rem);
    box-shadow: 0px 2px 20px 2px rgba(193, 208, 222, 0.3);
    background: $col-white;
    border-radius: max(2rem, 15px);
    width: max(500px, 64rem);
    text-align: center;
    overflow: auto;

    @include respondMax('tablet') {
      width: 85%;
      padding: max(15px, 4rem);
    }
    @include respondMax('mobile3x') {
      width: 95%;
      padding: max(15px, 2rem);
    }

    @include respondMax('mobile2x') {
      max-height: calc(100vh - $nav-height * 3);
    }

    img {
      margin-bottom: max(15px, 2rem);
      width: max(10rem, 60px);
      height: max(10rem, 60px);
    }

    h2 {
      @extend %text-28;
      color: $col-neutral-800;
      margin-bottom: max(30px, 5rem) !important;
    }

    footer {
      @extend %noprint;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: max(15px, 2rem);
      margin-top: 5rem;
      padding-inline: max(30px, 8rem);
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: max(20px, 2rem);
      @include respondMax('mobile') {
        gap: 20px;
      }

      li {
        display: flex;
        justify-content: space-between;
        gap: max(10px, 1rem);

        @include respondMax('mobile') {
          flex-direction: column;
          align-items: flex-start;
          gap: max(10px, 1rem);
        }
        p {
          @extend %text-16;
          text-align: left;
        }
        h4 {
          @extend %text-16;
          font-weight: 600;
        }
      }
    }
  }
}

.mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;

  border-top: 1px solid $col-bg;
  padding-block: max(15px, 2rem);
  gap: max(20px, 2rem);
  h4 {
    @extend %text-16;
  }

  p {
    @extend %text-14;
    margin-top: 4px;
  }

  span {
    @extend %center;
    display: inline-flex;
    padding: max(0.3rem, 3px) max(8px, 1.2rem);
    border-radius: $border-radius;
    @extend %text-14;

    text-transform: capitalize;
  }
}

.mobileRight {
  text-align: right;
  p {
    margin-left: auto;

    width: 25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}
.debit {
  color: $col-red;
}
.credit {
  color: $col-green;
}

.pending {
  color: $col-yellow-500;
  background: #fdf7f0;
}

.failed {
  color: $col-red;
}

.completed {
  background: #f7f7f7;
  color: $col-primary;
}
