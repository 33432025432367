@import '@/scss/import.scss';

.input {
  margin-top: max(20px, 3rem);
  position: relative;
  width: 100%;
  aside {
    @extend %text-12;
    color: $col-neutral-600;
    right: max(1.2rem, 10px);
    bottom: max(1.2rem, 10px);
    position: absolute;
  }
  label {
    @extend %text-16;
    font-weight: 600;
    color: $col-neutral-700;
    margin-top: 0;
    margin-bottom: max(1.25rem, 10px);
    display: block;
  }
  span {
    cursor: pointer;
    position: absolute;
    right: max(15px, 2rem);
    bottom: calc(25% - 5px);
    z-index: 2;
    height: 20px;
  }
  input {
    height: Max(45px, 5.6rem);
    text-indent: max(12px, 1.35rem);
    font-weight: 500;
  }

  textarea {
    padding: max(12px, 1.35rem);
    min-height: max(120px, 16rem);
    resize: none;
  }
  input,
  textarea {
    @extend %text-16;

    color: $col-neutral-800;
    border: 1.5px solid $col-neutral-500;
    border-radius: $border-radius;

    position: relative;
    width: 100%;
    outline: none;
    box-shadow: none;

    // appearance: textfield;
    // -moz-appearance: textfield;

    // &::-webkit-outer-spin-button,
    // &::-webkit-inner-spin-button {
    //   display: none;
    // }
    &:read-only {
      border: none !important;
    }
    &:focus,
    &:focus-visible {
      border: 1.5px solid $col-primary;
    }
    &::placeholder {
      @extend %text-16;
      color: $col-neutral-600;
    }
  }
  &_error {
    span {
      bottom: calc(50% - 10px);
    }
    input {
      border-color: $col-red !important;
      color: $col-red;
    }
    p {
      margin-top: 1rem;
      @extend %text-16;
      color: $col-red;
      letter-spacing: -0.006em;
    }
  }
}
