@import '@/scss/import.scss';

.preloader {
  @extend %cover;
  position: fixed;
  display: none;

  @extend %center;
  flex-direction: column;
  z-index: z('preloader');

  pointer-events: none;
  background: $col-primary;
  h1 {
    @extend %text-70;
    overflow: hidden;
    color: $col-white;
    span {
      display: inline-block;
      overflow: hidden;
    }
  }
}
