@import '@/scss/import.scss';

.check {
  display: inline-flex;
  align-items: center;
  gap: max(5px, 1rem);
  cursor: pointer;
  margin-top: max(15px, 2rem);
  p {
    @extend %text-16;
  }
  input {
    display: none;
  }
  span {
    display: block;
    height: max(2rem, 16px);
    width: max(2rem, 16px);
    border-radius: 0.5rem;
    margin-right: 0.3rem;
    border: 1px solid $col-primary;
  }

  svg {
    height: max(2.4rem, 20px);
    width: max(2.4rem, 20px);
  }
}
