%cover {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

%container {
  @include gutter;
  width: 100vw;
  margin-inline: auto;
}

%center {
  align-items: center;
  display: flex;
  justify-content: center;
}

%flex-ac {
  display: flex;
  align-items: center;
}

%flex-ac-jb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// buttons
%btn {
  font-size: max(14px, 1.6rem);
  font-weight: 600;

  width: 100%;
  height: max(48px, 6.4rem);
  transition: all 0.25s ease-out;
  border-radius: $border-radius;

  cursor: pointer;
  @include flex(center, center, row nowrap);

  svg {
    width: max(24px, 2.4rem);
    height: max(24px, 2.4rem);
  }
}

%btn_disabled {
  @extend %btn;
  color: $col-white;
  background-color: $col-disabled;
  border: 1px solid transparent;
  @include flex(center, center, row nowrap);
  &:hover {
    opacity: 1;
  }
}

%btn_primary {
  @extend %btn;
  color: $col-white;
  background-color: $col-primary;
  border: 1px solid $col-primary;
  &:hover {
    opacity: 0.8;
  }
}

%btn_secondary {
  @extend %btn;
  color: $col-primary;
  // background-color: $col-white;
  background-color: transparent;
  border: 1.5px solid $col-primary;
  &:hover {
    opacity: 0.8;
  }
}

%icon-40 {
  @extend %center;
  width: max(40px, 4rem);
  height: max(40px, 4rem);
  background: $col-neutral-400;
  border-radius: 100%;
}

%icon-48 {
  @extend %center;
  width: max(45px, 4.8rem);
  height: max(45px, 4.8rem);
  background: $col-neutral-300;
  border-radius: 100%;
}

%modal {
  @extend %center;
  @extend %cover;
  z-index: z('modal');
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
}

%noprint {
  @media print {
    display: none !important;
  }
}
