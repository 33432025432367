@import '@/scss/import.scss';

.loading {
  width: 20px;
  height: 20px;
  background: $col-purple;
  border-radius: 9999px;
  animation: beep 1s infinite forwards;
}
@keyframes beep {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  60% {
    transform: scale(0.8);
    opacity: 0.6;
  }
  70% {
    transform: scale(0.3);
    opacity: 0.2;
  }
  95% {
    transform: scale(0.7);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.select {
  margin-top: max(15px, 2rem);
  position: relative;
  width: 100%;
  label {
    @extend %text-16;
    font-weight: 600;
    color: $col-neutral-700;
    margin-top: 0;
    margin-bottom: max(1.25rem, 10px);
    display: block;
  }

  &_icon {
    height: max(15px, 2rem);
    width: max(15px, 2rem);
  }

  &_input {
    @extend %flex-ac-jb;
    @extend %text-16;
    cursor: pointer;

    border: 1.5px solid $col-neutral-500;
    border-radius: $border-radius;

    position: relative;
    width: 100%;
    height: Max(45px, 5.6rem);
    padding-inline: max(12px, 1.35rem);
    outline: none;
    box-shadow: none;
    input {
      height: 100%;
      width: 100%;
      border: none;
      outline: none;
      border-radius: $border-radius;
      @extend %text-16;
    }
    &.focus {
      border: 1.5px solid $col-primary;
    }
    &.disabled {
      opacity: 0.5;
    }
    span {
      color: $col-neutral-800;
      svg {
        display: inline-block;
        width: max(1.6rem, 16px);
        height: max(1.6rem, 16px);
      }
    }
  }

  .input {
    position: absolute;
    width: 80%;
    // opacity: 0;
  }

  .empty {
    p {
      @extend %text-16;
    }
  }
  .ul,
  .empty {
    position: absolute;
    top: calc(2rem + $top-nav-height);
    // bottom: 0;
    // transform: translateY(100%);
    background: $col-white;
    width: 100%;
    z-index: 2;
    box-shadow: $shadow-big-card;
    border-radius: 20px;
    padding-inline: max(12px, 1.35rem);
    padding-block: max(15px, 2rem);
    max-height: max(20rem, 200px);
    overflow: auto;

    p {
      @extend %text-20;
      color: $col-neutral-800;
      padding-inline: max(12px, 1.35rem);
    }

    li {
      @extend %text-16;
      @extend %flex-ac;
      color: $col-neutral-800;
      padding-inline: max(12px, 1.35rem);
      height: Max(36px, 4.8rem);
      border-radius: 8px;
      margin-bottom: max(0.5rem, 5px);
      &.active {
        background: $col-neutral-400 !important;
      }
      &:hover {
        background: $col-neutral-300;
      }
    }
  }
}

.multi {
  display: flex;
  flex-wrap: wrap;
  gap: max(8px, 1rem);
  padding-top: max(8px, 1rem);
  // margin-bottom: calc(1rem + $border-radius);
  li {
    @extend %center;
    @extend %text-14;
    font-weight: 600;
    letter-spacing: -0.006em;

    padding: 10px 16px;
    gap: max(8px, 1rem);
    border-radius: max(6px, 0.8rem);

    height: max(3.6rem, 30px);
    width: fit-content;

    color: $col-yellow-100;
    background: $col-yellow-200;

    text-transform: capitalize;

    @include respondMax(mobile) {
      font-size: 12px;
    }

    span {
      height: max(12px, 1.6rem);
      width: max(12px, 1.6rem);
      svg {
        display: inline-block;
        cursor: pointer;
        width: max(1.6rem, 16px);
        height: max(1.6rem, 16px);
      }
    }
  }
}

.industry {
  .multi {
    li {
      color: $col-green;
      background: $col-green-100;
      svg {
        path {
          stroke: $col-green;
        }
      }
    }
  }
}
