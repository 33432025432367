@import '@/scss/import.scss';

.check {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  span {
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-out;
    width: max(4rem, 34px);
    height: max(18px, 2rem);
    border-radius: $border-radius;
    background: #eceaf2;
    &:after {
      position: absolute;
      content: '';
      height: max(1.6rem, 14px);
      width: max(1.6rem, 14px);
      left: max(0.3rem, 2px);
      top: max(0.2rem, 2px);
      border-radius: 50%;
      background: $col-white;
      transition: all 0.2s ease-out;
    }
  }

  &.checked {
    span {
      background: $col-primary;
      &::after {
        transform: translateX(120%);
      }
    }
  }
}
