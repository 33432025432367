@import '@/scss/import.scss';

.product {
  width: 100%;
  img {
    object-fit: cover;
    width: 100%;
    border-radius: max(8px, 0.8rem);
    margin-bottom: max(10px, 1.25rem);
  }
  &_main {
    h2 {
      @extend %text-20;
      margin-bottom: max(8px, 0.8rem);
      line-height: 1.2;

      color: $col-neutral-800;

      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    &_bottom {
      color: $col-neutral-600;
      & > p {
        @extend %text-12;
      }

      div {
        @extend %flex-ac-jb;
        & > span {
          @extend %text-20;

          color: $col-primary;
        }
        p {
          @extend %text-12;
          @extend %flex-ac;
          gap: max(10px, 1.5rem);
          span {
            @extend %flex-ac;
            gap: max(5px, 0.5rem);
          }
        }
      }
    }
  }
}
