@import '@/scss/import.scss';

.pro {
  @extend %flex-ac;
  gap: max(20px, 3rem);
  padding-block: max(20px, 3rem);
  padding-inline: max(20px, 3rem);

  width: 100%;
  background: #f9f8ff;
  border-radius: $border-radius;

  & > img {
    height: max(12rem, 67px);
    width: max(9.2rem, 53px);
  }

  @include respondMax('mobile2x') {
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  &_div {
    display: flex;
    flex-direction: column;
    gap: max(14px, 1.6rem);
  }

  h4 {
    @extend %text-24;
    color: $col-black;
  }

  p {
    @extend %text-12;

    line-height: max(18px, 1.8rem);
  }

  &_btn {
    @extend %flex-ac;
    gap: max(1.6rem, 10px);
    @include respondMax('mobile2x') {
      justify-content: center;
    }
  }

  .pro_button {
    @extend %text-14;

    width: max(130px, 19.2rem);
    height: max(40px, 4.8rem);
    border-radius: max(1.2rem, 8px);
    &:last-child {
      width: max(100px, 13rem);
    }
  }
}
