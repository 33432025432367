@import '@/scss/import.scss';

.list {
  @extend %flex-ac;
  gap: max(1.5rem, 8px);
  & > span {
    @extend %center;
    min-width: max(5.6rem, 38px);
    height: max(5.6rem, 38px);
    background: #f8f8f8;
    border-radius: $border-radius;
    padding: max(1.25rem, 7px);
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .social_input {
    display: grid;
    grid-template-columns: 12fr 1fr;
    align-items: center;

    width: 100%;

    border: 1.5px solid $col-neutral-500;
    border-radius: $border-radius;
    padding-right: max(1.25rem, 10px);
    span {
      cursor: pointer;
    }
    input {
      @extend %text-16;
      color: $col-neutral-800;
      height: max(5.6rem, 45px);

      border: none;
      outline: none;
      padding-left: max(1.25rem, 10px);
      border-radius: $border-radius;
      background: none;
      &::placeholder {
        color: $col-neutral-600;
      }
    }

    div {
      display: flex;
      gap: max(1.25rem, 10px);
      span {
        svg {
          width: max(20px, 2.4rem);
          height: max(20px, 2.4rem);
        }
      }
    }
  }
}

.delete {
  @extend %modal;
  z-index: z('modal_two');

  &_main {
    @extend %center;
    flex-direction: column;
    position: relative;
    text-align: center;

    padding-block: $padding-inline;
    padding-inline: max(50px, 12rem);
    width: min(90%, 70rem);
    box-shadow: $shadow-big-card;

    background: $col-white;
    border-radius: max(2rem, 14px);

    @include respondMax('tablet') {
      padding-inline: 4rem;
    }
    svg {
      width: max(80px, 10rem);
      height: max(80px, 10rem);
    }

    h3 {
      @extend %text-28;
      color: $col-neutral-800;
      margin-block: max(2.5rem, 20px);
      @include respondMax('mobile2x') {
        font-size: max(1.6rem, 14px);
        line-height: 1.4;
      }
    }

    &_footer {
      display: flex;
      gap: max(14px, 2rem);
      button {
        width: max(16rem, 100px) !important;
      }
    }
  }
}
