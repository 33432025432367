@import '@/scss/import.scss';

.list {
  @extend %flex-ac-jb;
  gap: max(15px, 1.5rem);
  flex-wrap: wrap;
  padding: max(2rem, 10px) max(1.5rem, 15px);

  width: min(100%, 45rem);
  border: 1px solid #eeeaf6;
  border-radius: $border-radius;
  .present {
    @extend %text-12;
    @extend %center;

    width: max(6.25rem, 55px);
    height: max(3rem, 20px);
    background: $col-neutral-500;
    border-radius: max(4px, 0.4rem);
  }

  .time {
    @extend %text-12;
  }

  &_left {
    @extend %flex-ac;
    gap: max(10px, 1rem);

    span {
      width: max(4rem, 36px);
      height: max(4rem, 36px);

      background: $col-green-100;
      border-radius: max(4px, 0.4rem);
      @extend %center;
    }

    div {
      h3 {
        @extend %text-16;

        margin-bottom: max(0.5rem, 5px);
      }
      p {
        @extend %text-14;

        color: $col-neutral-600;
        line-height: 1;
      }
    }
  }

  &_right {
    @extend %flex-ac;
    gap: max(10px, 1.5rem);
  }
}

.delete {
  @extend %modal;

  &_main {
    @extend %center;
    flex-direction: column;
    position: relative;
    text-align: center;

    padding-block: $padding-inline;
    padding-inline: max(50px, 12rem);
    width: min(90%, 70rem);
    box-shadow: $shadow-big-card;

    background: $col-white;
    border-radius: max(2rem, 14px);

    @include respondMax('tablet') {
      padding-inline: 4rem;
    }
    svg {
      width: max(80px, 10rem);
      height: max(80px, 10rem);
    }

    h3 {
      @extend %text-28;
      color: $col-neutral-800;
      margin-block: max(2.5rem, 20px);
      @include respondMax('mobile2x') {
        font-size: max(1.6rem, 14px);
        line-height: 1.4;
      }
    }

    &_footer {
      display: flex;
      gap: max(14px, 2rem);
      button {
        width: max(16rem, 100px) !important;
      }
    }
  }
}

.add {
  @extend %modal;
  @extend %center;

  header {
    display: flex;
    justify-content: space-between;
    padding-top: max(4rem, 25px);
    padding-bottom: max(15px, 2rem);
    position: sticky;
    z-index: 1;
    top: 0;
    background: $col-white;
    h2 {
      @extend %text-28;
      color: $col-neutral-800;
      margin-bottom: 0.5rem;
    }
    p {
      @extend %text-16;
    }
    span {
      @extend %icon-40;
    }
  }

  &_main {
    padding-bottom: max(4rem, 25px);
    padding-inline: max(3.2rem, 25px);
    width: max(500px, 72rem);
    max-height: 95%;

    background: $col-white;
    box-shadow: $shadow-big-card;
    border-radius: max(15px, 2rem);

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-height: 750px) {
      overflow: auto;
    }

    @include respondMax('tablet') {
      width: 100%;
      max-height: unset;
      height: 100%;
    }

    form {
      @include respondMax('tablet') {
        padding-block: 5rem 10rem;
      }
      .flex {
        display: flex;
        gap: max(15px, 2rem);
      }
      & > button {
        margin-top: max(20px, 3rem);
      }
    }
  }
}

.icon {
  @include respondMax('tablet') {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max(3.6rem, 32px);
    height: max(3.6rem, 32px);
    background: #ffeef0;
    border-radius: 8px;
  }
}
