html {
  overflow-x: hidden;
  scroll-behavior: initial;
  overscroll-behavior: none;
  font-size: min(10px, calc(100vw / 1440 * 10));
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;

  --block: clamp(80px, 11vh, 11rem);
  @include respondMax('tablet') {
    font-size: calc(100vw / 768 * 10);
  }

  @include respondMax('mobile2x') {
    --block: clamp(80px, 10vh, 12rem);
    font-size: calc(100vw / 675 * 10);
  }
}

body {
  width: 100%;
  min-height: 100%;
  background-color: $col-white;
  color: $col-neutral-700;
  font-family: 'Figtree', sans-serif;
}

* {
  font-family: 'Figtree', sans-serif !important;

  &::-webkit-scrollbar {
    width: clamp(5px, 0.7rem, 7px);
    height: clamp(5px, 0.7rem, 7px);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $col-neutral-500;
    border-radius: $border-radius;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba($col-neutral-600, 0.5);
  }
}

a {
  color: $col-primary;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

button,
[role='tabpanel'],
[role='button'] {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}

.d-none {
  display: none;
}

input[data-type='search'] {
  width: max(340px, 34rem);
  height: max(40px, 4.8rem);

  outline: none;
  box-shadow: none;

  background: $col-neutral-200;
  border: 1px solid $col-neutral-500;
  border-radius: max(0.8rem, 8px);
  @extend %text-14;
  text-indent: max(25px, 3.5rem);

  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.66634 14.0002C11.1641 14.0002 13.9997 11.1646 13.9997 7.66683C13.9997 4.16903 11.1641 1.3335 7.66634 1.3335C4.16854 1.3335 1.33301 4.16903 1.33301 7.66683C1.33301 11.1646 4.16854 14.0002 7.66634 14.0002Z' stroke='%23B1AEC6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.6663 14.6668L13.333 13.3335' stroke='%23B1AEC6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: max(10px, 1rem);
  background-position-y: center;
  color: $col-neutral-700;
  appearance: textfield;
  -moz-appearance: textfield;
  &::placeholder {
    color: $col-neutral-600;
  }

  &::-webkit-search-cancel-button {
    display: none;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    border: 1.5px solid $col-primary;
  }

  @include respondMax('tablet') {
    width: 45rem;
  }

  @include respondMax('mobile2x') {
    width: 38rem;
    height: max(32px, 4rem);
    background-position-x: 95%;
    background-color: #f9f8ff;
    border-color: transparent;
    text-indent: max(12px, 1.6rem);
    &::placeholder {
      color: transparent;
    }
  }
}

.react-calendar {
  @include respondMax('mobile2x') {
    width: 100%;
  }
  border: none !important;
  &__navigation__label__labelText {
    font-size: max(2rem, 20px);
    font-weight: 600;
  }

  .react-calendar__navigation__label {
    pointer-events: none;
  }

  .react-calendar__month-view__days__day--weekend,
  .react-calendar__month-view__days__day--neighboringMonth,
  &__tile {
    transform: scale(0.85);
    font-size: max(1.6rem, 16px);
    background: $col-neutral-400;
    color: $col-primary;
    border-radius: 100%;
    aspect-ratio: 1;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background: $col-neutral-400;
    border-radius: 8px;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: $col-primary;
    color: $col-white;
  }

  .react-calendar__tile:disabled,
  .react-calendar__navigation button:disabled {
    background: transparent !important;
    color: $col-neutral-600;
  }

  .react-calendar__tile--now {
    // background: rgba($col-primary, 0.6);
    // color: $col-white;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translateX(-50%);
      background: $col-primary;
      height: max(0.6rem, 5px);
      width: max(0.6rem, 5px);
      border-radius: $border-radius;

      @include respondMax(mobile2x) {
        bottom: 15%;
      }
    }
  }

  .react-calendar__tile--active {
    background: $col-primary !important;
    color: $col-white;
  }

  .react-calendar__month-view__weekdays {
    abbr {
      @extend %text-12;
    }
  }

  abbr {
    text-transform: none;
    font-size: max(1.6rem, 16px);
    font-weight: 700 !important;
    text-decoration: none;
  }
}

.breadcrumb {
  @extend %text-14;
  @extend %flex-ac;
  gap: max(0.5rem, 5px);
  margin-bottom: max(15px, 2rem);
  margin-top: -3rem;
  white-space: nowrap;
  overflow: auto;

  position: sticky;
  top: $top-nav-height;
  background: inherit;
  z-index: z('breadcrumb');
  padding-top: calc(max(10px, 1rem) + max(4px, 0.6rem));
  padding-bottom: max(10px, 1rem);

  @include respondMax(tablet) {
  }
  svg {
    width: max(1.2rem, 12px);
    height: max(1.2rem, 12px);
    path {
      stroke-width: max(2px, 0.2rem);
    }
  }

  span {
    @extend %flex-ac;
    gap: max(0.5rem, 5px);
    color: $col-neutral-700;
    &:hover {
      svg {
        path {
          fill: $col-primary;
        }
      }
    }
  }

  a {
    @extend %flex-ac;
    gap: max(0.5rem, 5px);
    color: $col-neutral-700;
    // svg {
    //   path {
    //     fill: $col-neutral-700;
    //   }
    // }
  }
}

.str-chat {
  --str-chat__primary-color: #6e5ade;
  // --str-chat__active-primary-color: #004d40;
  --str-chat__font-family: 'figtree', sans-serif;
  // --str-chat__surface-color: #f5f5f5;
  // --str-chat__secondary-surface-color: #fafafa;
  // --str-chat__primary-surface-color: #e0f2f1;
  // --str-chat__primary-surface-color-low-emphasis: #edf7f7;
  // --str-chat__border-radius-circle: 6px;
  --str-chat__date-separator-line-color: #e6e5ea;
  --str-chat__date-separator-color: #94939a;
  --str-chat__message-textarea-border-radius: max(1.2rem, 10px);
  --str-chat__message-secondary-color: #94939a;
  --str-chat__message-bubble-background-color: #f8f8f8;
  --str-chat__message-bubble-color: #0a0a0a;
  --str-chat__own-message-bubble-background-color: #6e5ade;
  --str-chat__own-message-bubble-color: #ffffff;
  --str-chat__message-send-color: #6e5ade;
  --str-chat__body-medium-text: 500 max(12px, 1.5rem) var(--str-chat__font-family);
  --str-chat__caption-text: max(12px, 1.2rem) var(--str-chat__font-family);
  --str-chat__body-text: max(12px, 1.2rem) var(--str-chat__font-family);
  --str-chat__subtitle-text: max(12px, 1.4rem) var(--str-chat__font-family);
  --str-chat__body2-text: max(12px, 1.4rem) var(--str-chat__font-family);
  --str-chat__spacing-px: 1.1px;
}

.str-chat__main-panel {
  padding-bottom: max(10px, 1rem);
}

.countdown {
  @extend %text-14;
  @extend %flex-ac-jb;
  padding-inline: max(5px, 0.7rem) max(7px, 0.7rem);
  align-items: center;
  background: $col-green;
  color: $col-white;

  width: max(7.2rem, 70px);
  min-width: max(7.2rem, 70px);
  height: max(2.8rem, 26px);
  gap: max(5px, 0.5rem);
  border-radius: max(0.8rem, 8px);

  svg {
    width: max(2rem, 20px);
    height: max(2rem, 20px);
  }
  & > span {
    width: max(3.6rem, 35px);
  }
  &.timeout {
    background: #f23333;
  }
}

.countdownModal {
  position: fixed;
  z-index: z('modal');
  right: $inline;
  bottom: $inline;

  .content {
    @extend %center;
    flex-direction: column;
    gap: $border-radius;
    box-shadow: $shadow-big-card;

    position: relative;
    border-radius: max(2rem, 14px);
    width: max(320px, 42rem);

    background: $col-primary;
    padding-inline: 3rem;
    padding-top: 8rem;
    padding-bottom: 5rem;

    @include respondMax(mobile2x) {
      width: 90%;
    }

    [role='button'] {
      background: transparent;
      position: absolute;
      right: max(2rem, 15px);
      top: max(2rem, 15px);
    }

    div {
      display: flex;
      align-items: center;
      gap: $border-radius;
      @extend %text-28;

      letter-spacing: -0.006em;
      color: $col-red-500;
    }

    p {
      @extend %text-20;
      text-align: center;

      line-height: 1.5;
      letter-spacing: -0.006em;
      color: $col-neutral-400;
    }
  }
}

[role='navigation'] {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: max(5px, 0.5rem);
  margin-top: 2rem;
  // width: max(500px, 78rem);
  width: 100%;
  position: sticky;
  z-index: z('content');
  padding-left: 1rem;
  margin-left: -1rem;
  bottom: 0;
  background: $col-white;
  padding-block: calc(1rem + $border-radius);
  @include respondMax('tablet') {
    justify-content: center;
    bottom: $nav-height !important;
  }

  li {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: max(2.8rem, 24px);
    height: max(2.8rem, 24px);
    background: #f9f8ff;
    border-radius: 4px;
    @extend %text-12;

    &.selected {
      background: $col-primary;
      color: $col-white;
    }
    a {
      margin: 0 !important;
    }
  }

  :global(.disabled) {
    * {
      cursor: not-allowed;
    }

    svg {
      path {
        stroke: $col-neutral-700;
      }
    }
  }
}

.bcm {
  @extend %flex-ac-jb;
  background: $col-white;
  position: sticky;
  z-index: 3;
  top: max(63px, 7rem);
  padding-bottom: 10px;

  @include respondMax(mobile3x) {
    top: max(53px, 7rem);
  }
  h3 {
    font-size: max(16px, 1.6rem);
    color: $col-neutral-800;
    font-weight: 600 !important;
  }
}

.bcModal {
  @extend %flex-ac-jb;
  background: $col-white;
  position: sticky;
  top: 0;
  z-index: 3;
  padding-bottom: 10px;
  padding-top: 20px;
  h3 {
    font-size: max(16px, 1.6rem);
    color: $col-neutral-800;
    font-weight: 600 !important;
  }
}

.logotext {
  width: max(12rem, 90px) !important;
  aspect-ratio: 120/32 !important;
  border-radius: 0 !important;
  object-fit: unset !important;
}

.tac {
  text-align: center;
}
em,
b,
strong {
  color: inherit;
  font-weight: bold;
}
