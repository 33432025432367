@import '@/scss/import.scss';

.card {
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;
  color: inherit;
  // height: max(25rem, 200px);
  & > span {
    width: max(32px, 3.2rem);
    height: max(32px, 3.2rem);
    position: absolute;
    z-index: 1;
    top: max(10px, 1rem);
    left: max(10px, 1rem);
    padding: max(6px, 0.6rem);
    @extend %center;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 0, 0, 0.05) 77.6%,
      rgba(0, 0, 0, 0) 100%
    );

    svg {
      width: 100%;
      height: 100%;
      fill: rgba(102, 102, 102, 0.05);
    }
  }

  picture {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: top;
      background: $col-neutral-400;
      aspect-ratio: 201/252;
    }
  }

  &_main {
    position: absolute;
    bottom: max(6px, 1rem);
    left: max(6px, 1rem);
    right: max(6px, 1rem);
    padding: max(8px, 1rem);

    background: $col-white;
    border-radius: $border-radius;

    h4 {
      @extend %text-14;
      @extend %flex-ac;

      gap: max(2px, 0.5rem);
      margin-bottom: max(4px, 0.5rem);
      color: $col-neutral-800;
      span {
        width: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }

    p {
      @extend %flex-ac;
      @extend %text-12;

      gap: max(5px, 0.5rem);
      margin-bottom: max(5px, 0.5rem);
    }

    small {
      @extend %flex-ac;
      @extend %text-14;
      line-height: 1.25;
      margin-bottom: 2px;

      gap: max(5px, 0.5rem);

      color: $col-red-500;
    }
  }

  &_saved {
    @extend %text-12;
    @extend %center;
    padding-inline: 1rem;
    font-weight: 500;
    letter-spacing: -0.006em;
    color: $col-white;
    background: #313031;
    border-radius: max(5px, 0.6rem);
    position: absolute;
    left: calc(100% + 0.5rem);
    top: 0;
    bottom: 0;
    white-space: nowrap;
  }
}
