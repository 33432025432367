@import '@/scss/import.scss';

.alerts {
  position: fixed;
  width: 100vw;
  z-index: z('toast');
  top: calc(max(1.5rem, 10px) + $top-nav-height);
  left: $padding-inline;

  & > div {
    background: $col-white;
    @extend %flex-ac-jb;
    gap: max(10px, 1.5rem);
    width: max(40rem, 250px);

    box-shadow: 0px 0px 14px 2px rgba(59, 48, 122, 0.05);
    border-radius: $border-radius;
    padding: 1rem;

    @include respondMax('mobile2x') {
      width: calc(100% - $padding-inline * 2);
    }

    .svg {
      width: max(24px, 3.2rem);
      min-width: max(24px, 3.2rem);
      height: max(24px, 3.2rem);
    }

    &.error {
      background: #ffecee;
      box-shadow: 0px 0px 14px 2px rgba(59, 48, 122, 0.05);

      .btn {
        background: transparent;
      }
    }
    div {
      @extend %flex-ac;
      gap: max(10px, 1rem);
      span {
        @extend %text-14;
        line-height: max(16px, 2.4rem);
        color: $col-neutral-800;
      }
    }
  }
  .btn {
    @extend %center;
    width: max(30px, 3.2rem);
    height: max(30px, 3.2rem);
    background: $col-neutral-400;
    border-radius: 100%;
  }
}
