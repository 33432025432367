%text-10 {
  font-size: max(10px, 1rem);
  font-weight: 500;
}

%text-12 {
  font-size: max(12px, 1.2rem);
  font-weight: 500;
}

%text-14 {
  font-size: max(14px, 1.4rem);
  font-weight: 500;
  letter-spacing: -0.006em;
  line-height: 171%;
}

%text-16 {
  font-size: max(14px, 1.6rem);
  font-weight: 500;
}

%text-20 {
  font-size: max(16px, 2rem);
  font-weight: 500;

  letter-spacing: -0.006em;
  line-height: max(24px, 3.2rem);
}

%text-24 {
  font-size: max(18px, 2.4rem);
  font-weight: 500;
}

%text-28 {
  font-size: max(20px, 2.8rem);
  font-weight: 500;

  line-height: max(30px, 4rem);
}

%text-32 {
  font-size: max(24px, 3.2rem);
  font-weight: 600;
}

%text-36 {
  font-size: max(30px, 3.6rem);
  font-weight: 500;
}

%text-40 {
  font-size: max(28px, 4rem);
  font-weight: 500;
}

%text-48 {
  font-size: max(36px, 4.8rem);
  font-weight: 600;
}

%text-56 {
  font-size: max(35px, 5.6rem);
  letter-spacing: -0.006em;
  font-weight: 600;
}

%text-70 {
  font-size: 7rem;
  font-weight: 600;
}

%text-85 {
  font-size: 8.5rem;
  font-weight: 600;
}
