@import '@/scss/import.scss';

.broadcast {
  @extend %flex-ac;
  gap: calc($border-radius + 5px);
  color: $col-neutral-700;

  padding: max(1.5rem, 10px) max(2rem, 15px);
  border: 1px solid #eeeaf7;
  border-radius: $border-radius;
  margin-bottom: max(15px, 2rem);

  @include respondMax(mobile2x) {
    flex-wrap: wrap;
    padding: 10px;
    gap: max(1rem, 8px);
  }

  img {
    width: max(12.8rem, 70px);
    height: max(12.8rem, 70px);
    background: #e8f7f5;
    border-radius: $border-radius;
    aspect-ratio: 1;
    object-fit: cover;
  }

  h3 {
    @extend %text-20;

    letter-spacing: -0.006em;
    color: $col-neutral-800;
    margin-bottom: $border-radius;
  }

  &_list {
    @extend %flex-ac;
    gap: 4rem;
    @include respondMax(mobile2x) {
      gap: 10px;
    }
    div {
      @extend %text-14;
      display: flex;
      flex-direction: column;
      gap: calc($border-radius - 5px);
    }
  }
}
