@import '@/scss/import.scss';

.error {
  @extend %center;
  @extend %cover;
  flex-direction: column;
  h1 {
    @extend %text-32;
    color: $col-neutral-800;
    margin-bottom: max(15px, 2rem);
  }
  p {
    @extend %text-16;
    color: $col-neutral-800;
    margin-bottom: max(15px, 2rem);
  }
  div {
    @extend %center;
    gap: $border-radius;

    .btn {
      width: max(100px, 12rem);
      height: max(40px, 4.8rem);
    }
  }
}
