$col-white: #ffffff;
$col-black: #020813;

$col-neutral-800: #0a0a0a;
$col-neutral-700: #6d6c75;
$col-neutral-600: #94939a;
$col-neutral-500: #e6e5ea;
$col-neutral-400: #f8f8f8;
$col-neutral-300: #fcfcfc;
$col-neutral-200: #fdfdff;
$col-neutral-100: #f1f1f1;
$col-disabled: #b1aec6;

// $col-neutral-500: #e1e0ea;
// $col-neutral-400: #f8f7ff;
// $col-neutral-300: #fafafd;

$col-primary: #5e48db;
$col-primary-200: #a9a5c0;
$col-secondary-200: #2dc3f5;

$col-orange: #ea8b48;

$col-yellow: #e2ad04;
$col-yellow-100: #ffa93c;
$col-yellow-200: #fff7ec;
$col-yellow-400: #ffc478;
$col-yellow-500: #f9b732;

$col-green: #04ad68;
$col-green-500: #03a062;
$col-green-100: #f6f9f8;

$col-blue: #44d2f5;
$col-lilac: #cb86f2;
$col-purple: #6474e5;
$col-purple-100: #edeefa;
$col-red-100: #f8edf0;
$col-red: #c55261;
$col-red-500: #e67290;
$col-pink: #e9bec4;
$col-border: #f2f1f6;
$col-bg: #e8e8e8;
$shadow-big-card: 0px 2px 20px 2px rgba(193, 208, 222, 0.3);

$padding-block: var(--block);
$padding-inline: max(20px, 4rem);
$inline: max(20px, 3rem);
$border-radius: max(1.2rem, 10px);
$nav-height: max(8rem, 60px);
$top-nav-height: max(7.2rem, 53px);

$z-indexes: (
  'noscript',
  'preloader',
  'toast',
  'modal_two',
  'modal',
  'fullscreen',
  'navigation',
  'breadcrumb',
  'videoTabs',
  'loading',
  'footer',
  'content',
  '4',
  '3',
  '2',
  '1'
);

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out: cubic-bezier(0.77, 0, 0.175, 1);
