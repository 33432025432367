.stripe {
  padding-top: max(20px, 2rem);
  * {
    font-family: 'Figtree', sans-serif !important;
  }
  form {
    width: max(65rem, 300px);
    align-self: center;
    padding: 40px;
  }

  #payment-message {
    @extend %text-16;
    color: rgb(105, 115, 134);
    line-height: 20px;
    padding-top: $border-radius;
    text-align: center;
  }

  #payment-element {
    margin-bottom: max(20px, 2.4rem);
  }

  /* Buttons and links */
  button {
    @extend %center;
    @extend %text-16;
    background: $col-primary;
    width: 100%;
    height: max(40px, 4.8rem);

    color: $col-white;

    border-radius: $border-radius;
    border: 0;
    transition: all 0.2s ease;
    width: 100%;
    &:hover {
      transform: scale(0.95);
    }
    &:disabled {
      color: $col-white;
      background-color: $col-disabled;
      border: 1px solid transparent;
      @include flex(center, center, row nowrap);
      &:hover {
        transform: none;
      }
    }
  }

  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }

  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }

  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.paypal-buttons {
  margin-top: max(20px, 2rem);
}
