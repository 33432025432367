@import '@/scss/import.scss';

.payment_card {
  width: 100%;
  background: $col-white;
  border-radius: $border-radius;
  padding: max(25px, 4rem);
  margin-bottom: max(25px, 4rem);
  @include respondMax('tablet') {
    padding-inline: 0;
  }

  header {
    border-bottom: 1px solid $col-neutral-500;
    padding-bottom: max(25px, 4rem);
    h3 {
      @extend %text-24;
      color: $col-neutral-800;
    }
  }

  footer {
    padding-top: max(25px, 4rem);
    .btn {
      width: 75%;
      height: max(40px, 4.8rem);
      margin-inline: auto;
    }
  }

  &_item {
    border-bottom: 1px solid $col-neutral-500;
    padding-block: max(25px, 3rem);
    .btn {
      margin-top: max(25px, 3rem);
      width: max(180px, 50%);
      background: #ff9b00;
      color: $col-white;
      border: none;
    }
    .btnWallet {
      margin-top: max(25px, 3rem);
      width: max(180px, 50%);
      color: $col-white;
      border: none;
    }
    &_head {
      @extend %flex-ac;
      gap: $border-radius;
      @extend %text-20;

      transition: color 0.5s $ease-out-expo;
      &.active {
        color: $col-primary;
      }
    }
  }
}
